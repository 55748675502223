// WRITE ARTICLE
import { ClockIcon } from "@heroicons/vue/20/solid";

export const createATask = {
  order: 1,
  name: "Create a task",
  keywords: "task",
  subcategory: "Tasks",
  icon: ClockIcon,
  iconColor: "text-sky-400",
  markdown: `# Create a task`,
};
